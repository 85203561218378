import appState from 'src/store/slices/appStateSlice';
import blog from 'src/store/slices/blogSlice';
import category from 'src/store/slices/categorySlice';
import product from 'src/store/slices/productSlice';
import sections from 'src/store/slices/sectionsSlice';
import user from 'src/store/slices/userSlice';

import language from './languageSlice';

const reducers = {
  sections,
  user,
  appState,
  blog,
  category,
  product,
  language,
};

export default reducers;
