import React, { useState } from 'react';

interface DropdownProps {
  value: string;
  onChange: (value: string) => void;
  options: { value: string; label: string }[];
}

const DropDown: React.FC<DropdownProps> = ({ value, onChange, options }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option: string) => {
    onChange(option);
    setIsOpen(false);
  };

  return (
    <div className='relative z-10 inline-block'>
      {/* Dropdown Button */}
      <button
        onClick={toggleDropdown}
        className='flex w-auto cursor-pointer items-center rounded-sm bg-palette-backgroundDark px-1 py-1 text-left'>
        <span className='flex-grow text-xs font-light text-white'>
          {value || 'Select an option'}
        </span>
        <svg xmlns='http://www.w3.org/2000/svg' className='h-4 w-4' viewBox='0 0 24 24'>
          <path d='M19 9l-7 7-7-7' fill='#fff' />
        </svg>
      </button>

      {/* Dropdown Menu */}
      {isOpen && (
        <ul className='absolute left-0 top-full mt-px w-full overflow-hidden rounded-sm bg-palette-backgroundDark shadow-lg'>
          {options.map((option, index) => (
            <div key={index}>
              <li
                key={index}
                className='cursor-pointer px-1 py-px text-xs font-light text-white hover:bg-palette-background'
                onClick={() => handleSelect(option.value)}>
                {option.label}
              </li>
              {index !== options.length - 1 && (
                <div className='h-px w-full bg-palette-background' />
              )}
            </div>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DropDown;
