import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Search } from 'lucide-react';
import useProducts from 'src/hooks/useProducts';
import { cn } from 'src/lib/utils';

export default function SearchBar({ isOpen = false }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getProductsBySearch } = useProducts();

  return (
    <div className={cn('group flex h-8 rounded-full', isOpen && 'hover')}>
      <div
        className={cn(
          'grid transition-all group-hover:grid-cols-[1fr]',
          isOpen ? 'grid-cols-[1fr]' : 'grid-cols-[0fr]'
        )}>
        <div className='overflow-hidden'>
          <input
            className='h-full rounded-full rounded-r-none border border-r-0 border-palette-backgroundDark pl-4 text-palette-text outline-none'
            placeholder={t('search')}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                const url = '/products?search=' + e.currentTarget.value;
                getProductsBySearch(e.currentTarget.value).then(() => {
                  navigate(url);
                });
              }
            }}
          />
        </div>
      </div>
      <div
        className={cn(
          'flex aspect-square h-full items-center justify-center rounded-full bg-palette-backgroundDark',
          isOpen
            ? 'rounded-bl-none rounded-tl-none'
            : 'group-hover:rounded-bl-none group-hover:rounded-tl-none'
        )}>
        <Search className='text-white' size={14} />
      </div>
    </div>
  );
}
