import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import useBlog from 'src/hooks/useBlog';
import useLanguage from 'src/hooks/useLanguage';
import MediaURL from 'src/utils/mediaURL';

import Footer from '../../layout/footer';
import Header from '../../layout/header';

export default function BlogPage() {
  const { language } = useLanguage();
  const { t } = useTranslation();
  const { currentBlog, getBlog, blogs } = useBlog();
  const { slug } = useParams();
  const title = 'title_' + language.code;
  const body = 'body_' + language.code;

  useEffect(() => {
    if (!slug) return;
    getBlog(slug);
    // eslint-disable-next-line
  }, [slug]);

  return (
    <>
      <Header />
      <div className='maxw mx-auto grid grid-cols-1 gap-8 p-4 md:grid-cols-3'>
        {/* Sol taraf - Blog Detayları */}
        <div className='col-span-2'>
          {currentBlog && (
            <div className='rounded-lg'>
              <img
                src={MediaURL(currentBlog.image)}
                alt={currentBlog[title]}
                className='w-full rounded-t-lg'
              />
              <div className='p-6'>
                <h1 className='text-3xl font-semibold text-palette-text'>{currentBlog[title]}</h1>
                <div
                  className='mt-4 text-sm text-gray-600'
                  dangerouslySetInnerHTML={{ __html: currentBlog[body] }}
                />
              </div>
            </div>
          )}
        </div>

        {/* Sağ taraf - Küçük Postlar */}
        <div className='hidden md:block'>
          <h3 className='mb-4 text-lg font-semibold text-palette-text'>{t('otherPosts')}</h3>
          <div className='space-y-4'>
            {blogs.map(post => (
              <div
                key={post.slug}
                className='flex items-center overflow-hidden rounded-lg border border-gray-200 bg-white shadow-sm'>
                <img
                  src={MediaURL(post.image)}
                  alt={post[title]}
                  className='h-12 w-12 object-cover'
                />
                <div className='ml-4'>
                  <h4 className='text-sm font-medium text-palette-text'>{post[title]}</h4>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
