import { toast } from 'sonner';
import { loginService, tokenRefreshService } from 'src/service/services';
import { useAppDispatch, useAppSelector } from 'src/store';
import { setError, setUser } from 'src/store/slices/userSlice';

export default function useAuth() {
  const user = useAppSelector(state => state.user.user);
  const isAuth = !!user?.access;
  const dispath = useAppDispatch();

  const loadUser = () => {
    const user = localStorage.getItem('user');
    if (user) {
      dispath(setUser(JSON.parse(user)));
      return JSON.parse(user).refresh;
    }

    return false;
  };

  const login = async (email: string, password: string) => {
    const res = await loginService({ email, password });

    if (res?.status === 1) {
      dispath(setUser(res.result));
      localStorage.setItem('user', JSON.stringify(res.result));
      toast.success('Login success');
      return true;
    } else {
      dispath(setError('Login failed'));
      toast.error('Login failed');
      return false;
    }
  };

  const refreshToken = async (refresh: string) => {
    if (!refresh) return;
    const user = JSON.parse(localStorage.getItem('user') || 'false');
    if (!user) return;
    const res = await tokenRefreshService({ refresh });

    if (res?.status === 1) {
      dispath(setUser({ ...user, access: res.result.access }));
      localStorage.setItem('user', JSON.stringify({ ...user, access: res.result.access }));
    } else {
      dispath(setError('Token could not be refreshed'));
    }
  };

  const logout = () => {
    dispath(setUser(null));
    localStorage.removeItem('user');
  };

  return {
    isAuth,
    login,
    refreshToken,
    loadUser,
    logout,
  };
}
