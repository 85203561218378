import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import useBlog from 'src/hooks/useBlog';
import useLanguage from 'src/hooks/useLanguage';
import MediaURL from 'src/utils/mediaURL';

import Footer from '../../layout/footer';
import Header from '../../layout/header';

export default function BlogList() {
  const { language } = useLanguage();
  const { t } = useTranslation();
  const { blogs, getBlogs } = useBlog();
  const title = 'title_' + language.code;
  const body = 'body_' + language.code;

  useEffect(() => {
    if (blogs.length > 0) return;
    getBlogs();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header />
      <div className='maxw mx-auto grid grid-cols-1 gap-8 p-4 md:grid-cols-3'>
        <div className='col-span-2'>
          {blogs.map(blog => (
            <div
              key={blog.slug}
              className='mb-8 rounded-lg border border-gray-200 bg-white shadow-md'>
              <Link to={`/blog/${blog.slug}`}>
                <img
                  src={MediaURL(blog.image)}
                  alt={blog[title]}
                  className='mb-4 h-48 w-full rounded-t-lg object-cover'
                />
                <div className='p-6'>
                  <h2 className='text-2xl font-semibold text-palette-text'>{blog[title]}</h2>
                  <div
                    className='mt-4 text-sm text-gray-600'
                    dangerouslySetInnerHTML={{ __html: blog[body].slice(0, 200).trim() + '...' }}
                  />
                </div>
              </Link>
            </div>
          ))}
        </div>

        <div className='hidden md:block'>
          <h3 className='mb-4 text-lg font-semibold text-palette-text'>{t('otherPosts')}</h3>
          <div className='space-y-4'>
            {blogs.map(post => (
              <div
                key={post.slug}
                className='flex items-center overflow-hidden rounded-lg border border-gray-200 bg-white shadow-sm'>
                <img
                  src={MediaURL(post.image)}
                  alt={post[title]}
                  className='h-12 w-12 object-cover'
                />
                <div className='ml-4'>
                  <h4 className='text-sm font-medium text-palette-text'>{post[title]}</h4>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
