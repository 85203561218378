import { useEffect, useMemo, useState } from 'react';

import { File, MoreHorizontal, PlusCircle, Search } from 'lucide-react';
import CrudContent from 'src/components/crud-content';
import { CustomBreadCrumb } from 'src/components/custom-breadcrumb';
import { FieldType } from 'src/components/input-generator';
import RenderItem from 'src/components/render-item';
import { Button } from 'src/components/ui/button';
import { Card, CardContent } from 'src/components/ui/card';
import { Drawer, DrawerContent, DrawerTrigger } from 'src/components/ui/drawer';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from 'src/components/ui/dropdown-menu';
import { Input } from 'src/components/ui/input';
import { Table, TableBody, TableCell, TableHeader, TableRow } from 'src/components/ui/table';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'src/components/ui/tabs';
import { EPermissions } from 'src/consts/enums';
import useSection from 'src/hooks/useSection';
import { useAppSelector } from 'src/store';

export default function Dashboard() {
  const language = useAppSelector(state => state.appState.language);
  const [crudType, setCrudType] = useState(EPermissions.update);
  const [options, setOptions] = useState<any>([]);
  const [selectedTab, setSelectedTab] = useState('all');
  const { searchTerm, setSearch } = useSection();
  const [inputSearchTerm, setInputSearchTerm] = useState(searchTerm);
  const { loadMoreItem, sectionItems, currentSection, getOptions } = useSection();
  const filteredItems = useMemo(() => {
    if (!sectionItems) return null;
    if (selectedTab === 'all') return sectionItems;
    if (selectedTab === 'active')
      return sectionItems.filter((item: any) => item.is_active === true);
    if (selectedTab === 'inactive')
      return sectionItems.filter((item: any) => item.is_active === false);
  }, [selectedTab, sectionItems]);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const isAtBottom = scrollTop + windowHeight >= documentHeight;
    if (isAtBottom) {
      loadMoreItem();
    }
  };

  useEffect(() => {
    getOptions(currentSection.endpoint).then((res: any) => {
      setOptions(res);
    });
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    //eslint-disable-next-line
  }, [currentSection]);

  const fieldFormatter = (product: any, fieldName: string) => {
    if (options?.result?.translation_fields?.includes(fieldName)) {
      return product[`${fieldName}_${language}`];
    }
    if (options?.result?.actions?.POST[fieldName].type === 'choice') {
      return product[fieldName]?.name;
    }
    return product[fieldName];
  };

  return (
    <div className='flex min-h-screen w-full flex-col bg-muted/40'>
      <div className='flex flex-col sm:gap-4 sm:py-4'>
        <main className='grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0'>
          <CustomBreadCrumb />
          <Tabs defaultValue='all' onValueChange={value => setSelectedTab(value as string)}>
            <div className='flex items-center justify-between'>
              <TabsList>
                <TabsTrigger value='all'>All</TabsTrigger>
                <TabsTrigger value='active'>Active</TabsTrigger>
                <TabsTrigger value='inactive'>In Active</TabsTrigger>
              </TabsList>
              <div className='ml-4 flex items-center gap-2'>
                <div className='relative ml-auto flex-1 md:grow-0'>
                  <Search className='absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground' />
                  <Input
                    value={inputSearchTerm}
                    onChange={e => setInputSearchTerm(e.currentTarget.value)}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        setSearch(inputSearchTerm);
                      }
                    }}
                    type='search'
                    placeholder='Search...'
                    className='w-full rounded-lg bg-background pl-8 md:w-[175px] lg:w-[336px]'
                  />
                </div>
                <Button size='sm' variant='outline' className='h-8 gap-1'>
                  <File className='h-3.5 w-3.5' />
                  <span className='sr-only sm:whitespace-nowrap md:not-sr-only'>Export</span>
                </Button>
                {currentSection.permissions.includes(EPermissions.create) && (
                  <Drawer>
                    <DrawerTrigger>
                      <Button size='sm' className='h-8 gap-1'>
                        <PlusCircle className='h-3.5 w-3.5' />
                        <span className='sr-only truncate md:not-sr-only'>{`Add ${currentSection.name}`}</span>
                      </Button>
                    </DrawerTrigger>
                    <DrawerContent className='mx-auto max-w-[80%] border-gray-700'>
                      <div className='max-h-svh overflow-y-auto'>
                        <CrudContent type={EPermissions.create} section={currentSection} />
                      </div>
                    </DrawerContent>
                  </Drawer>
                )}
              </div>
            </div>
            <TabsContent value={selectedTab}>
              <Card x-chunk='dashboard-06-chunk-0'>
                <CardContent>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        {(options?.result?.table_fields || []).map(
                          (field: { name: string; type: string }, i: number) => (
                            <TableCell key={i}>
                              {field.name.charAt(0).toUpperCase() + field.name.slice(1)}
                            </TableCell>
                          )
                        )}
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {filteredItems &&
                        filteredItems.map((product: any, i: number) => (
                          <TableRow key={i}>
                            {(options?.result?.table_fields || []).map(
                              (field: { name: string; type: string }, j: number) => (
                                <>
                                  <TableCell key={j}>
                                    {
                                      <RenderItem
                                        type={field.type as FieldType}
                                        value={fieldFormatter(product, field.name)}
                                      />
                                    }
                                  </TableCell>
                                </>
                              )
                            )}

                            <TableCell>
                              <Drawer>
                                <DropdownMenu>
                                  <DropdownMenuTrigger asChild>
                                    <Button aria-haspopup='true' size='icon' variant='ghost'>
                                      <MoreHorizontal className='h-4 w-4' />
                                      <span className='sr-only'>Toggle menu</span>
                                    </Button>
                                  </DropdownMenuTrigger>
                                  <DropdownMenuContent className='flex flex-col' align='end'>
                                    <DropdownMenuLabel>Actions</DropdownMenuLabel>
                                    {currentSection.permissions.includes(EPermissions.update) && (
                                      <>
                                        <DrawerTrigger
                                          onClick={() => {
                                            setCrudType(EPermissions.update);
                                          }}>
                                          <DropdownMenuItem>Edit</DropdownMenuItem>
                                        </DrawerTrigger>
                                      </>
                                    )}
                                    {currentSection.permissions.includes(EPermissions.delete) && (
                                      <DrawerTrigger
                                        onClick={() => {
                                          setCrudType(EPermissions.delete);
                                        }}>
                                        <DropdownMenuItem>Delete</DropdownMenuItem>
                                      </DrawerTrigger>
                                    )}
                                  </DropdownMenuContent>
                                </DropdownMenu>

                                <DrawerContent className='mx-auto max-w-[80%] border-gray-700'>
                                  <div className='max-h-svh overflow-y-auto'>
                                    <CrudContent
                                      type={crudType}
                                      section={currentSection}
                                      initialData={product}
                                    />
                                  </div>
                                </DrawerContent>
                              </Drawer>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </TabsContent>
          </Tabs>
        </main>
      </div>
    </div>
  );
}
