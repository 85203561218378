import { EStatus } from 'src/consts/enums';
import { getBlogService, getBlogsService } from 'src/service/services';
import { useAppDispatch, useAppSelector } from 'src/store';
import { setBlogs, setCurrentBlog, setError, setStatus } from 'src/store/slices/blogSlice';

export default function useBlog() {
  const dispatch = useAppDispatch();
  const currentBlog = useAppSelector(state => state.blog.currentBlog);
  const blogs = useAppSelector(state => state.blog.blogs);
  const status = useAppSelector(state => state.blog.status);
  const error = useAppSelector(state => state.blog.error);

  const getBlogs = async () => {
    try {
      const response = await getBlogsService();
      console.log(response);
      dispatch(setBlogs(response.result));
      dispatch(setStatus(EStatus.succeeded));
    } catch (error) {
      dispatch(setError(error));
      dispatch(setStatus(EStatus.failed));
    }
  };

  const getBlog = async (slug: string) => {
    try {
      const response = await getBlogService(slug);
      dispatch(setCurrentBlog(response.result));
    } catch (error) {
      console.log(error);
    }
  };

  return { blogs, status, error, getBlogs, getBlog, currentBlog };
}
