import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { MenuIcon } from 'lucide-react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'src/components/ui/accordion';
import { Label } from 'src/components/ui/label';
import { Sheet, SheetContent, SheetTrigger } from 'src/components/ui/sheet';
import useCategory from 'src/hooks/useCategory';

import { nav } from './consts';
import SocialIcons from './social-icons';

export default function MobileNav() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { categories, getCategories, order } = useCategory();

  useEffect(() => {
    if (categories.length > 0) return;
    getCategories();
    // eslint-disable-next-line
  }, []);

  const recursiveRenderItem = (category: ICategory, href?: string) => {
    return (
      <>
        {category.children.length > 0 ? (
          <AccordionItem value={category.slug}>
            <AccordionTrigger>
              <Label>{category.name}</Label>
            </AccordionTrigger>
            <AccordionContent>
              <div className='ml-4 flex flex-col gap-4'>
                {category.children.map((child, index) => (
                  <div key={index}>{recursiveRenderItem(child, href)}</div>
                ))}
              </div>
            </AccordionContent>
          </AccordionItem>
        ) : (
          <Label
            onClick={e => {
              e.stopPropagation();
              if (href) {
                navigate(href + '/' + category.slug);
              }
            }}>
            {category.name}
          </Label>
        )}
      </>
    );
  };

  return (
    <Sheet>
      <SheetTrigger asChild>
        <MenuIcon className='h-6 w-6' />
      </SheetTrigger>
      <SheetContent className='flex w-full flex-col items-center justify-between p-4'>
        <Accordion type='multiple' className='w-full space-y-4'>
          {nav.map((navItem, index) => (
            <div key={index}>
              {navItem.title === 'products' ? (
                <AccordionItem value={navItem.title}>
                  <AccordionTrigger>
                    <Label className='block py-2 font-light'>{t(navItem.title)}</Label>
                  </AccordionTrigger>
                  <AccordionContent>
                    {order.map((row, index) => (
                      <div key={index}>
                        {row.map((col, index) => (
                          <div key={index}>
                            {categories.find(c => c.slug === col) &&
                              recursiveRenderItem(
                                categories.find(c => c.slug === col) as ICategory,
                                navItem.href
                              )}
                          </div>
                        ))}
                      </div>
                    ))}
                  </AccordionContent>
                </AccordionItem>
              ) : (
                <Label className='block py-2 font-light'>{t(navItem.title)}</Label>
              )}
            </div>
          ))}
        </Accordion>
        <SocialIcons />
      </SheetContent>
    </Sheet>
  );
}
