import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import useSection from 'src/hooks/useSection';
import AdminHeader from 'src/layout/admin-header';
import AdminSide from 'src/layout/admin-side';
import NotFound from 'src/layout/not-found';
import Admin from 'src/pages/admin';
import { useAppSelector } from 'src/store';

export default function ProtectedNavigation() {
  const { loadItems, currentSection } = useSection();
  const sections = useAppSelector(state => state.sections.sections);

  useEffect(() => {
    loadItems(currentSection.name);
    // eslint-disable-next-line
  }, [currentSection]);

  return (
    <>
      <AdminHeader />
      <div className='flex'>
        <AdminSide />
        <div className='admin-content w-full'>
          <Routes>
            {sections.map((section: any) => (
              <Route key={section.name} path={section.path} element={<Admin />} />
            ))}

            <Route path='*' element={<NotFound />} />
          </Routes>
        </div>
      </div>
    </>
  );
}
