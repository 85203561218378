import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import App from 'src/App';
import { ThemeProvider } from 'src/components/theme-provider';
import { Toaster } from 'src/components/ui/sonner';
import 'src/index.css';
import 'src/localization/index';
import { store } from 'src/store';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <ThemeProvider attribute='class' defaultTheme='light'>
      <App />
      <Toaster richColors />
    </ThemeProvider>
  </Provider>
);
