import { tokenRefresh } from 'src/consts/endpoints';
import { EStatus } from 'src/consts/enums';
import { tokenRefreshService } from 'src/service/services';
import { store } from 'src/store';
import { setStatus, setUser } from 'src/store/slices/userSlice';

const dispatch = store.dispatch;

export const tokenRefresher = (instance: any) => {
  instance.interceptors.response.use(
    (response: any) => response,
    async (error: any) => {
      const originalRequest = error.config;
      if (
        error.response?.status === 401 &&
        !originalRequest._retry &&
        originalRequest.url !== tokenRefresh
      ) {
        originalRequest._retry = true;
        const user = JSON.parse(localStorage.getItem('user') || 'false');
        if (!user) return Promise.reject(error);

        const res = await tokenRefreshService({ refresh: user.refresh });
        if (res?.status === 1) {
          localStorage.setItem('user', JSON.stringify({ ...user, access: res.result.access }));
          originalRequest.headers.Authorization = `Bearer ${res.result.access}`;
          const response = await instance(originalRequest);
          return response;
        } else {
          console.error('Token refresh failed');
          window.location.href = '/login';
          dispatch(setUser(null));
          dispatch(setStatus(EStatus.idle));
          localStorage.removeItem('user');
          return Promise.reject(error);
        }
      }

      return Promise.reject(error);
    }
  );
};
