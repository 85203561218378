enum FieldType {
  STRING = 'string',
  URL = 'url',
  DATETIME = 'datetime',
  EMAIL_LIST = 'email_list',
  IMAGE_UPLOAD = 'image',
  FILE_UPLOAD = 'file',
  FLOAT = 'float',
  INTEGER = 'integer',
  BOOLEAN = 'boolean',
  CHOICE = 'choice',
  HTML_FIELD = 'html',
  EMAIL = 'email',
  FIELD = 'field',
}

interface RenderItemProps {
  type: FieldType;
  value: any;
}

export default function RenderItem({ type, value }: RenderItemProps) {
  switch (type) {
    case FieldType.STRING:
    case FieldType.URL:
    case FieldType.EMAIL:
    case FieldType.FIELD:
      return <div>{value}</div>;
    case FieldType.DATETIME:
      return <div>{new Date(value).toLocaleString()}</div>;
    case FieldType.EMAIL_LIST:
      return (
        <div>
          {value.map((email: string) => (
            <div key={email}>{email}</div>
          ))}
        </div>
      );
    case FieldType.FILE_UPLOAD:
      return (
        <div>
          <a href={value} target='_blank' rel='noreferrer'>
            {value}
          </a>
        </div>
      );

    case FieldType.IMAGE_UPLOAD:
      return (
        <div>
          <img src={value} alt='img' className='h-10 w-10' />
        </div>
      );
    case FieldType.FLOAT:
    case FieldType.INTEGER:
      return <div>{value}</div>;
    case FieldType.BOOLEAN:
      return <div>{value ? 'True' : 'False'}</div>;
    case FieldType.CHOICE:
      return <div>{value}</div>;
    case FieldType.HTML_FIELD:
      return (
        <div>
          <div dangerouslySetInnerHTML={{ __html: value }} />
        </div>
      );
    default:
      return null;
  }
}
