import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import * as icon from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { cn } from 'src/lib/utils';
import { useAppSelector } from 'src/store';

export default function AdminSide() {
  const sections = useAppSelector(state => state.sections.sections);
  const location = useLocation();
  const currentPath = useMemo(() => {
    if (location.pathname === '/admin') return '/';
    return location.pathname.split('/').pop();
  }, [location]);

  return (
    <aside className='hidden flex-col border-r bg-background md:flex'>
      <nav className='flex flex-col items-center gap-4 px-2 sm:py-5 w-60'>
        {sections.map((section, i) => (
          <div className='flex w-full' key={i}>
            <Link
              to={section.path === '/' ? '/admin' : `/admin/${section.path}`}
              className={cn(
                'flex items-center rounded-lg text-muted-foreground transition-colors hover:text-foreground w-full p-2',
                currentPath === section.path && 'bg-accent text-accent-foreground'
              )}>
              {/* @ts-ignore */}
              <CIcon icon={icon[section.icon]} className='h-5 w-5' />
              <span className='flex-1 pl-2'>{section.name}</span>
            </Link>
          </div>
        ))}
      </nav>
    </aside>
  );
}
