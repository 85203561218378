import { EStatus } from 'src/consts/enums';
import {
  getProductService,
  getProductsByCategoryService,
  getProductsBySearchService,
  getProductsService,
} from 'src/service/services';
import { useAppDispatch, useAppSelector } from 'src/store';
import { setCurrentProduct, setProducts, setStatus } from 'src/store/slices/productSlice';

export default function useProducts() {
  const dispatch = useAppDispatch();

  const products = useAppSelector(state => state.product.products);
  const currentProduct = useAppSelector(state => state.product.currentProduct);
  const status = useAppSelector(state => state.product.status);
  const error = useAppSelector(state => state.product.error);

  const getProducts = async () => {
    try {
      const response: any = await getProductsService();
      dispatch(setProducts(response.result));
      dispatch(setStatus(EStatus.succeeded));
      return response.result;
    } catch (error) {
      console.log(error);
    }
  };

  const getProductsByCategory = async (slug: string) => {
    try {
      const response = await getProductsByCategoryService(slug);
      dispatch(setProducts(response.result));
      dispatch(setStatus(EStatus.succeeded));
    } catch (error) {
      console.log(error);
    }
  };

  const getProductsBySearch = async (search: string) => {
    try {
      const response = await getProductsBySearchService(search);

      dispatch(setProducts(response.result));
      dispatch(setStatus(EStatus.succeeded));
    } catch (error) {
      console.log(error);
    }
  };

  const getProduct = async (slug: string) => {
    try {
      const response = await getProductService(slug);
      dispatch(setCurrentProduct(response.result));
    } catch (error) {
      console.log(error);
    }
  };

  return {
    products,
    status,
    error,
    currentProduct,
    getProducts,
    getProduct,
    getProductsByCategory,
    getProductsBySearch,
  };
}
