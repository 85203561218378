import axios from 'axios';
import { authInterceptor } from 'src/middleware/network/auth';
import { loggerInterceptor } from 'src/middleware/network/logger';
import { tokenRefresher } from 'src/middleware/network/token-refresher';

const language = localStorage.getItem('language')
  ? JSON.parse(localStorage.getItem('language') || '')
  : 'en';

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_BASE_URL + '/api',
  headers: {
    'Content-Type': 'multipart/form-data',
    'Accept-Language': language.code,
  },
});

authInterceptor(axiosInstance);
tokenRefresher(axiosInstance);
if (process.env.REACT_APP_MOD === 'development') {
  loggerInterceptor(axiosInstance);
}

export default axiosInstance;
