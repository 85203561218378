import React from 'react';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { useTheme } from 'next-themes';
import { DatePicker } from 'src/components/ui/date-picker';
import { Input } from 'src/components/ui/input';
import { Label } from 'src/components/ui/label';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/components/ui/select';
import { Switch } from 'src/components/ui/switch';

import './quill.css';
import { Tabs, TabsContent, TabsList, TabsTrigger } from './ui/tabs';

export enum FieldType {
  STRING = 'string',
  URL = 'url',
  DATETIME = 'datetime',
  EMAIL_LIST = 'email_list',
  IMAGE_UPLOAD = 'image',
  FILE_UPLOAD = 'file',
  FLOAT = 'float',
  INTEGER = 'integer',
  BOOLEAN = 'boolean',
  CHOICE = 'choice',
  HTML_FIELD = 'html',
  EMAIL = 'email',
  FIELD = 'field',
}

export interface Field {
  label: string;
  required: boolean;
  read_only: boolean;
  type: FieldType;
  max_length?: number;
  choices?: [
    {
      value: string;
      display_name: string;
    },
  ];
}

export interface Fields {
  [key: string]: Field;
}

interface Data {
  [key: string]: any;
}

interface FormOrderItem {
  type: 'tab' | 'row';
  fields: string[];
}

export default function InputGenerator({
  fields,
  form_order,
  data = {},
  setData,
  translationFields,
}: {
  fields: Fields;
  form_order: FormOrderItem[];
  data: Data;
  setData: React.Dispatch<React.SetStateAction<Data>>;
  languages?: string[];
  translationFields?: string[];
}) {
  const { systemTheme } = useTheme();

  const renderField = (fieldKey: string, field: Field, showLabel: boolean = true) => {
    const { label, required, read_only, type, max_length } = field;
    const formattedLabel = translationFields?.some(prop => label.toLowerCase().includes(prop))
      ? label.split(' ').join('(') + ')'
      : label;
    const value = data[fieldKey] || '';
    const handleChange = (e: any) => {
      const newValue = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
      setData(prevData => ({ ...prevData, [fieldKey]: newValue }));
    };

    switch (type) {
      case FieldType.STRING:
      case FieldType.URL:
      case FieldType.EMAIL:
      case FieldType.FIELD:
        return (
          <div key={fieldKey}>
            {showLabel && <Label htmlFor={fieldKey}>{formattedLabel}</Label>}
            <Input
              id={fieldKey}
              type='text'
              value={value}
              readOnly={read_only}
              maxLength={max_length}
              required={required}
              onChange={handleChange}
            />
          </div>
        );
      case FieldType.DATETIME:
        return (
          <div className='flex gap-2 items-center' key={fieldKey}>
            {showLabel && <Label htmlFor={fieldKey}>{formattedLabel}</Label>}
            <DatePicker
              value={new Date(value)}
              onChange={date => setData(prevData => ({ ...prevData, [fieldKey]: date }))}
            />
          </div>
        );
      case FieldType.EMAIL_LIST:
        return (
          <div key={fieldKey}>
            {showLabel && <Label htmlFor={fieldKey}>{formattedLabel}</Label>}
            <ReactMultiEmail
              style={{
                backgroundColor: systemTheme === 'dark' ? '#0a0e1a' : '#ffffff',
                borderColor: systemTheme === 'dark' ? '#1f2a42' : '#d4e0e8',
                color: systemTheme === 'dark' ? '#f6fbff' : '#3b0d0c',
              }}
              inputClassName='bg-background text-text'
              emails={value}
              onChange={_emails => setData(prevData => ({ ...prevData, [fieldKey]: _emails }))}
              placeholder='Add email addresses...'
              getLabel={(email, index, removeEmail) => (
                <div className='flex gap-2 bg-accent p-1.5 rounded' key={index}>
                  <div>{email}</div>
                  <span
                    className='text-background bg-primary px-1 rounded cursor-pointer select-none'
                    onClick={() => removeEmail(index)}>
                    ×
                  </span>
                </div>
              )}
            />
          </div>
        );
      case FieldType.IMAGE_UPLOAD:
      case FieldType.FILE_UPLOAD:
        return (
          <div key={fieldKey}>
            {showLabel && <Label htmlFor={fieldKey}>{formattedLabel}</Label>}
            <Input
              id={fieldKey}
              type='file'
              readOnly={read_only}
              required={required}
              onChange={e => {
                const file = e.target.files?.[0];
                if (file) {
                  setData(prevData => ({ ...prevData, [fieldKey]: file }));
                }
              }}
            />
            {typeof value === 'string' && (
              <div className='text-border text-sm'>{value?.split('/').pop()}</div>
            )}
          </div>
        );
      case FieldType.FLOAT:
      case FieldType.INTEGER:
        return (
          <div key={fieldKey}>
            {showLabel && <Label htmlFor={fieldKey}>{formattedLabel}</Label>}
            <Input
              id={fieldKey}
              type='number'
              value={value}
              readOnly={read_only}
              required={required}
              onChange={handleChange}
            />
          </div>
        );
      case FieldType.BOOLEAN:
        return (
          <div className='flex gap-2 items-center' key={fieldKey}>
            {showLabel && <Label htmlFor={fieldKey}>{formattedLabel}</Label>}
            <Switch
              id={fieldKey}
              checked={Boolean(value)}
              onCheckedChange={checked => {
                setData(prevData => ({ ...prevData, [fieldKey]: checked }));
              }}
              disabled={read_only}
            />
          </div>
        );
      case FieldType.CHOICE:
        return (
          <div key={fieldKey}>
            {showLabel && <Label htmlFor={fieldKey}>{formattedLabel}</Label>}
            <Select
              value={data[fieldKey] || ''}
              disabled={read_only}
              key={fieldKey}
              required={required}
              onValueChange={value => {
                setData(prevData => ({ ...prevData, [fieldKey]: value }));
              }}>
              <SelectTrigger>
                <SelectValue placeholder='Seçiniz' />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {field.choices?.map(choice => (
                    <SelectItem key={choice.value} value={choice.value}>
                      {choice.display_name}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        );

      case FieldType.HTML_FIELD:
        return (
          <div key={fieldKey}>
            {showLabel && <Label htmlFor={fieldKey}>{formattedLabel}</Label>}
            <ReactQuill
              className='custom-quill'
              value={value}
              onChange={content => setData(prevData => ({ ...prevData, [fieldKey]: content }))}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const renderFormOrder = () => {
    return form_order.map((item, index) => {
      const { type, fields: fieldKeys } = item;
      if (type === 'tab') {
        const tabs = fieldKeys.map(fieldKey => fieldKey.split('_')[1]);
        return (
          <div key={index} className='tabs'>
            <Tabs defaultValue={tabs[0]}>
              <TabsList>
                {tabs.map(tab => (
                  <TabsTrigger key={tab} value={tab}>
                    {tab.charAt(0).toUpperCase() + tab.slice(1)}
                  </TabsTrigger>
                ))}
              </TabsList>
              {fieldKeys.map(fieldKey => {
                if (!fields[fieldKey]) return null;
                return (
                  <TabsContent key={fieldKey} value={fieldKey.split('_')[1]}>
                    {renderField(fieldKey, fields[fieldKey])}
                  </TabsContent>
                );
              })}
            </Tabs>
          </div>
        );
      }
      if (type === 'row') {
        return (
          <div key={index} className='flex flex-row gap-4'>
            {fieldKeys.map(fieldKey => {
              const field = fields[fieldKey];
              if (field) {
                return <div className='w-full'> {renderField(fieldKey, field)}</div>;
              }
              return null;
            })}
          </div>
        );
      }
      return null;
    });
  };

  return <form className='flex flex-col gap-4'>{renderFormOrder()}</form>;
}
