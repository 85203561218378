import { BookImage, Facebook, Instagram, Linkedin, Youtube } from 'lucide-react';

export default function SocialIcons() {
  const containerClasses = 'bg-palette-backgroundDark h-8 w-8 rounded-full p-2';
  const iconClasses = 'text-white h-4 w-4';
  return (
    <div className='flex h-8 items-center gap-2'>
      <a href='https://www.youtube.com/@ssaofficial-tv' className={containerClasses}>
        <Youtube className={iconClasses} />
      </a>
      <a href='https://www.linkedin.com/company/ssakimya/' className={containerClasses}>
        <Linkedin className={iconClasses} />
      </a>
      <a href='https://www.instagram.com/ssakimya/' className={containerClasses}>
        <Instagram className={iconClasses} />
      </a>
      <a
        href='https://www.facebook.com/people/SSA-Kimya/100076283407582/'
        className={containerClasses}>
        <Facebook className={iconClasses} />
      </a>
      <div className={containerClasses}>
        <BookImage className={iconClasses} />
      </div>
    </div>
  );
}
