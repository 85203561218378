import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { toast } from 'sonner';
import { getItemsService, getOptionsService } from 'src/service/services';
import { useAppDispatch, useAppSelector } from 'src/store';
import { setSearchTerm, setSectionItems } from 'src/store/slices/appStateSlice';

export default function useSection() {
  const searchParamName = 's';
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const dispath = useAppDispatch();
  const sections = useAppSelector(state => state.sections.sections);
  const allItems: any[] | null = useAppSelector(state => state.appState.sectionItems);

  //exported things
  const searchTerm = useAppSelector(state => state.appState.searchTerm);

  const currentSection = useMemo(() => {
    const path = location.pathname.split('/').pop();
    return sections.find(section => section.path === path) || sections[0];
  }, [location.pathname, sections]);

  const sectionItems = useMemo(() => {
    if (!allItems) return null;
    return allItems?.find(item => item.sectionName === currentSection.name)?.items;
    //eslint-disable-next-line
  }, [allItems, currentSection, searchTerm]);

  const setSearch = (search: string) => {
    dispath(setSearchTerm(search));
  };

  const loadMoreItem = async () => {
    console.log('load more');
  };

  const loadItems = async (sectionName: string) => {
    const section = sections.find(section => section.name === sectionName);
    if (!section) return;
    try {
      const res = await getItemsService(section?.endpoint);
      if (res?.status === 1) {
        const el = allItems?.find(item => item.sectionName === sectionName);

        if (el) {
          dispath(
            setSectionItems(
              allItems?.map(item =>
                item.sectionName === sectionName ? { ...item, items: res.result } : item
              )
            )
          );
        } else {
          dispath(
            setSectionItems([
              ...(allItems || []),
              {
                sectionName,
                items: res.result,
                nextPageUrl: res?.next,
              },
            ])
          );
        }
      } else {
        toast.error('Could not get items');
      }
    } catch (error) {
      toast.error('Could not get items');
    }
  };

  const getOptions = async (endpoint: string) => {
    try {
      const res = await getOptionsService(endpoint);

      if (res?.status === 1) {
        return res;
      } else {
        toast.error('Could not get options');
      }
    } catch (error) {
      toast.error('Could not get options');
    }
  };

  const updateItem = (slug: string, data: any) => {
    dispath(
      setSectionItems(
        allItems?.map(item =>
          item.sectionName === currentSection.name
            ? {
                ...item,
                items: item.items.map((i: any) => (i.slug === slug ? data : i)),
              }
            : item
        )
      )
    );
    toast.success('Item updated');
  };

  const deleteItem = (slug: string) => {
    dispath(
      setSectionItems(
        allItems?.map(item =>
          item.sectionName === currentSection.name
            ? {
                ...item,
                items: item.items.filter((i: any) => i.slug !== slug),
              }
            : item
        )
      )
    );
    toast.success('Item deleted');
  };

  const addItem = (data: any) => {
    dispath(
      setSectionItems(
        allItems?.map(item =>
          item.sectionName === currentSection.name
            ? {
                ...item,
                items: [data, ...item.items],
              }
            : item
        )
      )
    );
    toast.success('Item added');
  };

  //automated functions
  useEffect(() => {
    if (!searchTerm.length) {
      params.delete(searchParamName);
    } else {
      params.set(searchParamName, searchTerm);
    }
    let newUrl = window.location.pathname;
    const paramString = params.toString();
    if (paramString.length > 0) {
      newUrl += `?${paramString}`;
    }
    window.history.replaceState(null, '', newUrl);
    //eslint-disable-next-line
  }, [searchTerm]);

  return {
    currentSection,
    searchTerm,
    sectionItems,
    setSearch,
    loadMoreItem,
    loadItems,
    getOptions,
    updateItem,
    deleteItem,
    addItem,
  };
}
