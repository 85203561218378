import * as endpoints from 'src/consts/endpoints';
import {
  deleteRequest,
  getRequest,
  optionsRequest,
  patchRequest,
  postRequest,
} from 'src/service/api';

export const loginService = async (data: any) => {
  try {
    return await postRequest(endpoints.login, data);
  } catch (error) {
    console.log(error);
  }
};

export const tokenVerifyService = async () => {
  try {
    return await getRequest(endpoints.tokenVerify);
  } catch (error) {
    console.log(error);
  }
};

export const tokenRefreshService = async (data: any) => {
  try {
    return await postRequest(endpoints.tokenRefresh, data);
  } catch (error) {
    console.log(error);
  }
};

export const getOptionsService = async (url: string) => {
  try {
    return await optionsRequest(url);
  } catch (error) {
    console.log(error);
  }
};

export const getItemsService = async (url: string) => {
  try {
    return await getRequest(url);
  } catch (error) {
    console.log(error);
  }
};

export const postItemService = async (url: string, data: any) => {
  try {
    return await postRequest(url, data);
  } catch (error) {
    console.log(error);
  }
};

export const updateItemService = async (url: string, data: any) => {
  try {
    return await patchRequest(url, data);
  } catch (error) {
    console.log(error);
  }
};

export const deleteItemService = async (url: string) => {
  try {
    return await deleteRequest(url);
  } catch (error) {
    console.log(error);
  }
};

export const getBlogsService = async () => {
  try {
    return await getRequest(endpoints.blog);
  } catch (error) {
    console.log(error);
  }
};

export const getBlogService = async (slug: string) => {
  try {
    return await getRequest(`${endpoints.blog}${slug}/`);
  } catch (error) {
    console.log(error);
  }
};

export const getCategoryTreeService = async () => {
  try {
    return await getRequest(endpoints.category + '/tree/');
  } catch (error) {
    console.log(error);
  }
};

export const getProductsService = async () => {
  try {
    return await getRequest(endpoints.product);
  } catch (error) {
    console.log(error);
  }
};

export const getProductService = async (slug: string) => {
  try {
    return await getRequest(`${endpoints.product}/${slug}/`);
  } catch (error) {
    console.log(error);
  }
};

export const getProductsByCategoryService = async (slug: string) => {
  try {
    return await getRequest(`${endpoints.product}?category=${slug}`);
  } catch (error) {
    console.log(error);
  }
};

export const getProductsBySearchService = async (search: string) => {
  try {
    return await getRequest(`${endpoints.product}?search=${search}`);
  } catch (error) {
    console.log(error);
  }
};
