import { EStatus } from 'src/consts/enums';
import { getCategoryTreeService } from 'src/service/services';
import { useAppDispatch, useAppSelector } from 'src/store';
import { setCategories, setOrder, setStatus } from 'src/store/slices/categorySlice';

export default function useCategory() {
  const dispatch = useAppDispatch();

  const categories = useAppSelector(state => state.category.categories);
  const order = useAppSelector(state => state.category.order);
  const status = useAppSelector(state => state.category.status);
  const error = useAppSelector(state => state.category.error);

  const getCategories = async () => {
    try {
      const response: any = await getCategoryTreeService();
      dispatch(setCategories(response.result.categories));
      dispatch(setOrder(response.result.order));
      dispatch(setStatus(EStatus.succeeded));
    } catch (error) {
      console.log(error);
    }
  };

  return { categories, status, error, getCategories, order };
}
