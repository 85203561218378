interface NavItem {
  title: string;
  href?: string;
}

export const nav: NavItem[] = [
  {
    title: 'home',
    href: '/',
  },
  {
    title: 'ssaView',
  },
  {
    title: 'products',
    href: '/products',
  },
  {
    title: 'news',
  },
  {
    title: 'hr',
  },
  {
    title: 'blog',
    href: '/blogs',
  },
  {
    title: 'contact',
  },
];

export const solutionsContent = [
  {
    title: 'GÜVENLİK',
    description:
      'Sağladığımız çözümler ile insanların çalışma ortamlarını güvenli hale getiriyoruz.',
  },
  {
    title: 'VERİMLİLİK',
    description:
      'Ülke endüstrimizin gelişimine katkıda bulunmak ve üretimde verimi arttırmak için çalışıyoruz.',
  },
  {
    title: 'SÜRDÜRÜLEBİLİRLİK',
    description: 'Sürekli öğrenmeye ve bütün kaynaklarımızı doğru kullanmaya odaklanıyoruz.',
  },
];

export const isgMaterials = [
  {
    title: 'AYAK KORUYUCULARI',
    color: '#f5ec42',
    image: '/images/isg-shoe.jpeg',
  },
  {
    title: 'EL ve KOL KORUYUCULARI',
    color: '#f55442',
    image: '/images/isg-shoe.jpeg',
  },
  {
    title: 'VUCUT KORUYUCULARI',
    color: '#171616',
    image: '/images/isg-shoe.jpeg',
  },
  {
    title: 'KAFA ve YÜZ KORUYUCULARI',
    color: '#51f542',
    image: '/images/isg-shoe.jpeg',
  },
  {
    title: 'ÇEVRE ve SAĞLIK KORUYUCULARI',
    color: '#f5b642',
    image: '/images/isg-shoe.jpeg',
  },
  {
    title: 'ALEV ALMAZ KIYAFETLER',
    color: '#9942f5',
    image: '/images/isg-shoe.jpeg',
  },
];

export const news = [
  {
    image: '/images/news-image.jpeg',
    title: 'Kişisel Koruyucu Kullanım Alanları ve Standartları?',
    upText: '09.09.2024',
    description:
      'Kişisel koruyucu donanımlar, risklerin ortadan kaldırılamadığı yada mühendislik önlemlerinin yapılamadığı durumlarda çalışanları risklerden koruyan ekipmanlardır.',
  },
  {
    image: '/images/news-image.jpeg',
    title: 'Kişisel Koruyucu Kullanım Alanları ve Standartları?',
    upText: '09.09.2024',
    description:
      'Kişisel koruyucu donanımlar, risklerin ortadan kaldırılamadığı yada mühendislik önlemlerinin yapılamadığı durumlarda çalışanları risklerden koruyan ekipmanlardır.',
  },
  {
    image: '/images/news-image.jpeg',
    title: 'Kişisel Koruyucu Kullanım Alanları ve Standartları?',
    upText: '09.09.2024',
    description:
      'Kişisel koruyucu donanımlar, risklerin ortadan kaldırılamadığı yada mühendislik önlemlerinin yapılamadığı durumlarda çalışanları risklerden koruyan ekipmanlardır.',
  },
];

export const productPageSuggestOptions = [
  'Ürün açıklaması yetersiz',
  'Açıklamada verilen bilgilerde hatalar var',
  'Ürün resmi görünmüyor veya kalitesiz',
  'Sayfada teknik bir problem var',
  'Fiyat bilgisi hatalı',
  'Bu ürün diğer sitelere göre burada daha pahalı',
];

export const otherProductsYouMayLike = [
  { title: 'Dräger X-plore 3300/3500', image: '/images/other-product.jpeg' },
  { title: 'Dräger X-plore 3300/3500', image: '/images/other-product.jpeg' },
  { title: 'Dräger X-plore 3300/3500', image: '/images/other-product.jpeg' },
  { title: 'Dräger X-plore 3300/3500', image: '/images/other-product.jpeg' },
  { title: 'Dräger X-plore 3300/3500', image: '/images/other-product.jpeg' },
];
