import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Clock, MailIcon, MapPin, PhoneCallIcon } from 'lucide-react';
import useCategory from 'src/hooks/useCategory';
import { cn } from 'src/lib/utils';

export default function Footer() {
  const { categories, getCategories } = useCategory();
  const { t } = useTranslation();

  useEffect(() => {
    if (categories.length > 0) return;
    getCategories();
    // eslint-disable-next-line
  }, []);

  const recursiveRenderItem = (category: ICategory) => {
    return (
      <div className='py-2 text-white'>
        <div
          className={cn(
            'text-xs font-normal',
            category.parent === null && 'font-bold',
            category.children.length === 0 && 'underline'
          )}>
          {category.name}
        </div>

        {category.children.length > 0 &&
          category.children.map((child, index) => (
            <div key={index} className='ml-4'>
              {recursiveRenderItem(child)}
            </div>
          ))}
      </div>
    );
  };

  return (
    <>
      <footer className='grid grid-cols-1 bg-palette-primary px-10 py-20 md:grid-cols-4'>
        <div>
          <img src='/images/logo-white.svg' alt='Logo' className='mb-10 h-16' />
          <a className='mb-5 flex' href='mailto:info@ssakimya.com'>
            <MailIcon className='text-white' size={20} />
            <div className='ml-2 text-sm font-light text-white underline'>info@ssakimya.com</div>
          </a>
          <a href='/' className='mb-5 flex'>
            <MapPin className='text-white' size={20} />
            <div className='ml-2 w-72 text-sm font-light text-white'>
              ABC Plaza Sarıyer Cad. 117-B Kat: 4 No: 10 İstinye – Sarıyer / İSTANBUL
            </div>
          </a>
          <a href='/' className='mb-5 flex'>
            <Clock className='text-white' size={20} />
            <div className='ml-2 text-sm font-light text-white'>
              {t('weekday') + ': 8:00 - 17:30 - ' + t('weekend') + ': ' + t('closed')}
            </div>
          </a>
          <a href='/' className='flex'>
            <PhoneCallIcon className='text-sm text-white' size={20} />
            <div className='ml-2 font-light text-white'>+90 212 262 05 00</div>
          </a>
        </div>

        <div className='mt-10 flex flex-col md:mt-0 md:items-center'>
          <div>
            {['home', 'about', 'hr', 'news', 'blog', 'contact'].map((item, index) => (
              <div key={index} className='font-normal text-white'>
                {t(item)}
              </div>
            ))}
          </div>
        </div>

        <div className='col-span-2 mt-10 md:mt-0'>
          <div className='grid grid-cols-2'>
            {categories.map((category, index) => (
              <div key={index}>{recursiveRenderItem(category)}</div>
            ))}
          </div>
        </div>
      </footer>
      <div className='flex justify-center bg-palette-background py-4 text-xs font-light text-white'>
        {t('subFooterText')}
      </div>
    </>
  );
}
