import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import useAuth from 'src/hooks/useAuth';
import NotFound from 'src/layout/not-found';
import ProtectedNavigation from 'src/navigation/protected-navigation';
import HomePage from 'src/pages/home';
import BlogPage from 'src/pages/home/pages/blog';
import Blogs from 'src/pages/home/pages/blogs';
import Product from 'src/pages/home/pages/product';
import Products from 'src/pages/home/pages/products';
import LoginPage from 'src/pages/login';

export default function Navigation() {
  const { isAuth } = useAuth();

  return (
    <Router>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/login' element={isAuth ? <Navigate to='/admin/' /> : <LoginPage />} />
        <Route path='/product/:slug' element={<Product />} />
        <Route path='/products/:slug' element={<Products />} />
        <Route path='/products' element={<Products />} />
        <Route path='/blogs' element={<Blogs />} />
        <Route path='/blog/:slug' element={<BlogPage />} />

        {isAuth && <Route path='/admin/*' element={<ProtectedNavigation />} />}

        <Route path='*' element={<NotFound />} />

      </Routes>
    </Router>
  );
}
