import { useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import * as icon from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { PanelLeft, User2 } from 'lucide-react';
import { Button } from 'src/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'src/components/ui/dropdown-menu';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetTitle,
  SheetTrigger,
} from 'src/components/ui/sheet';
import useAuth from 'src/hooks/useAuth';
import { sections } from 'src/store/slices/sectionsSlice';

export default function AdminHeader() {
  const closeRef = useRef<any>(null);
  const navigate = useNavigate();
  const { logout } = useAuth();
  return (
    <header className='flex items-center justify-between gap-4 border-b bg-background px-4 py-2 sm:static sm:h-auto sm:bg-transparent sm:px-6'>
      <Sheet>
        <SheetTitle className='sr-only'>
          <div className='sr-only'>Menu</div>
        </SheetTitle>
        <SheetTrigger asChild>
          <Button size='icon' variant='outline' className='md:hidden'>
            <PanelLeft className='h-5 w-5' />
            <span className='sr-only'>Toggle Menu</span>
          </Button>
        </SheetTrigger>
        <SheetContent side='left' className='sm:max-w-xs'>
          <SheetDescription className='sr-only'>Menu</SheetDescription>
          <nav className='grid gap-6 text-lg font-medium'>
            {sections.map(section => (
              <SheetClose
                key={section.name}
                ref={closeRef}
                onClick={() => closeRef.current.click()}>
                <Link
                  to={section.path}
                  className='flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground'>
                  {/* @ts-ignore */}
                  <CIcon icon={icon[section.icon]} className='h-5 w-5' />
                  {section.name}
                </Link>
              </SheetClose>
            ))}
          </nav>
        </SheetContent>
      </Sheet>
      <Link to='/'>
        <img src='/logo192.png' alt='Logo' className='h-8' />
      </Link>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant='outline' size='icon' className='overflow-hidden rounded-full'>
            <div>
              <User2 className='h-5 w-5' />
            </div>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align='end'>
          <DropdownMenuLabel>My Account</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={() => {
              logout();
              navigate('/login');
            }}>
            Logout
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </header>
  );
}
