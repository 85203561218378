import { ChevronRight } from 'lucide-react';

interface CardProps {
  image: string;
  upText?: string;
  title?: string;
  description?: string;
  redirect?: string;
}

export default function Card(props: CardProps) {
  return (
    <div className='bg- flex max-w-60 flex-col items-center gap-2 overflow-hidden rounded-2xl bg-palette-backgroundLighter'>
      <div>
        <img src={props.image} alt={props.title} className='w-full' />
      </div>
      <div className='p-3'>
        {props.upText && (
          <div className='text-xs font-normal text-palette-textSecondary'>{props.upText}</div>
        )}
        {props.title && <div className='text-sm font-semibold text-gray-600'>{props.title}</div>}
        {props.description && (
          <div className='mt-2 text-xs font-normal text-gray-500'>{props.description}</div>
        )}
        {props.redirect && <ChevronRight className='ml-auto mt-2 text-palette-primary' />}
      </div>
    </div>
  );
}
