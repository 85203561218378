export const authInterceptor = (axios: any) => {
  axios.interceptors.request.use((config: any) => {
    const whiteList = [
      '/auth/login/',
      '/auth/token/refresh/',
      '/category/tree/',
      '/product/',
      '/blog/',
    ];
    if (whiteList.includes(config.url)) {
      return config;
    }
    const user = localStorage.getItem('user');
    const parsedUser = user ? JSON.parse(user) : null;
    if (parsedUser?.access) {
      config.headers.Authorization = `Bearer ${parsedUser.access}`;
    }
    return config;
  });
};
