import { createSlice } from '@reduxjs/toolkit';

interface IAppState {
  language: string;
  searchTerm: string;
  sectionItems:
    | {
        nextPageUrl: string;
        sectionName: string;
        items: any[];
      }[]
    | null;
}

const initialState: IAppState = {
  language: navigator.language.split('-')[0] === 'tr' ? 'tr' : 'en',
  searchTerm: '',
  sectionItems: null,
};

const appStateSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    setSearchTerm(state, action) {
      state.searchTerm = action.payload;
    },
    setSectionItems(state, action) {
      state.sectionItems = action.payload;
    },
  },
});

export const { setSectionItems, setSearchTerm } = appStateSlice.actions;

export default appStateSlice.reducer;
