import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { ChevronRight } from 'lucide-react';
import { CustomBreadCrumb } from 'src/components/custom-breadcrumb';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'src/components/ui/accordion';
import { Label } from 'src/components/ui/label';
import { EStatus } from 'src/consts/enums';
import useCategory from 'src/hooks/useCategory';
import useProducts from 'src/hooks/useProducts';
import { cn } from 'src/lib/utils';
import MediaURL from 'src/utils/mediaURL';

import Footer from '../../layout/footer';
import Header from '../../layout/header';

export default function Products() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { slug } = useParams();
  const { categories, getCategories, status: categoryStatus } = useCategory();
  const { getProductsByCategory, getProducts, products, status: productStatus } = useProducts();

  useEffect(() => {
    if (categories.length > 0) return;
    getCategories();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (slug) {
      getProductsByCategory(slug);
    } else {
      getProducts();
    }
    // eslint-disable-next-line
  }, [slug]);

  const renderCategory = (category: ICategory) => {
    return (
      <AccordionItem
        onClick={e => {
          e.stopPropagation();
          navigate(`/products/${category.slug}`);
        }}
        className={cn(
          category.parent !== null && 'ml-4 border-0 border-l',
          category.children.length === 0 && 'pt-4'
        )}
        value={category.slug}>
        {category.children.length !== 0 ? (
          <AccordionTrigger>
            <Label>{category.name}</Label>
          </AccordionTrigger>
        ) : (
          <Label>{category.name}</Label>
        )}
        <AccordionContent>
          {category.children.length > 0 &&
            category.children.map((child, index) => <div key={index}>{renderCategory(child)}</div>)}
        </AccordionContent>
      </AccordionItem>
    );
  };

  // this function is used to get the path of the category to topparent
  const getCategoryPath = (slug: string): string => {
    if (!categories.length) return '';
    let currentPath = slug;
    let searchCategories = categories;
    let currentSlug = slug;

    // eslint-disable-next-line
    while (!categories.find(c => c.slug === currentSlug)) {
      const allChildrens = searchCategories.flatMap(c => c.children);
      // eslint-disable-next-line
      const el = allChildrens.find(c => c.slug === currentSlug);

      if (el) {
        const parent = searchCategories.find(c => c.children.includes(el));
        if (!parent) break;
        currentPath = `${parent.slug}/${currentPath}`;
        currentSlug = parent.slug;
        searchCategories = categories;
      } else {
        searchCategories = allChildrens;
      }
    }

    return currentPath;
  };

  if (categoryStatus !== EStatus.succeeded) {
    return null;
  }

  return (
    <>
      <Header />

      <div className='maxw mt-5 pl-5'>
        <CustomBreadCrumb path={`${t('products')}/${slug ? getCategoryPath(slug) : ''}`} />
      </div>
      <div className='maxw flex items-start justify-between py-5'>
        <Accordion
          defaultValue={slug ? getCategoryPath(slug).split('/') : []}
          type='multiple'
          className='sticky left-0 top-0 w-3/12'>
          <div className='px-5'>
            {categories.map((category, index) => (
              <div key={index}>{renderCategory(category)}</div>
            ))}
            {categoryStatus !== EStatus.succeeded && (
              <div className='flex flex-col gap-2'>
                {Array.from({ length: 10 }).map((_, index) => (
                  <div
                    key={index}
                    className='h-8 w-full animate-pulse rounded-lg bg-palette-backgroundLight'
                  />
                ))}
              </div>
            )}
          </div>
        </Accordion>

        <div className='grid w-9/12 grid-cols-2 gap-2 md:grid-cols-4'>
          {products.map((product, index) => (
            <div
              onClick={() => navigate(`/product/${product.slug}`)}
              className='group flex cursor-pointer flex-col overflow-hidden rounded-lg border-2'
              key={index}>
              <img
                src={MediaURL(product.product_images[0].image)}
                alt='Product'
                className='h-40 w-full object-cover'
              />
              <div className='flex h-full flex-col justify-between px-2'>
                <div className='mt-2 text-start text-sm font-medium'>{product.name}</div>

                <div className='flex justify-end'>
                  <div className='relative my-2 cursor-pointer border-b-[0.5px] border-palette-text text-end text-sm font-light transition-all group-hover:translate-y-0.5'>
                    {t('productDetail')}
                    <ChevronRight className='inline-block' size={16} />
                    <div className='absolute h-0.5 w-0 bg-palette-primary transition-all group-hover:w-full' />
                  </div>
                </div>
              </div>
            </div>
          ))}
          {productStatus !== EStatus.succeeded &&
            Array.from({ length: 20 }).map((_, index) => (
              <div
                key={index}
                className='h-40 w-full animate-pulse rounded-lg bg-palette-backgroundLight'
              />
            ))}
        </div>
      </div>
      <Footer />
    </>
  );
}
