import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { CustomBreadCrumb } from 'src/components/custom-breadcrumb';
import useProducts from 'src/hooks/useProducts';
import { cn } from 'src/lib/utils';
import MediaURL from 'src/utils/mediaURL';

import Card from '../../components/card';
import { productPageSuggestOptions } from '../../components/consts';
import Footer from '../../layout/footer';
import Header from '../../layout/header';

export default function Product() {
  const navigate = useNavigate();
  const { slug } = useParams();
  const { t } = useTranslation();
  const { getProduct, currentProduct, products, getProductsByCategory } = useProducts();
  const [currentProductImage, setCurrentProductImage] = useState<string>(
    '/images/product-mask.jpeg'
  );

  useEffect(() => {
    if (!slug) {
      navigate('/products');
      return;
    }
    getProduct(slug);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currentProduct) {
      getProductsByCategory(currentProduct.category.slug);
    }
    // eslint-disable-next-line
  }, [currentProduct]);

  return (
    <>
      <Header />
      <div className='relative h-96'>
        <img
          src='/images/product-carousel.jpeg'
          className='h-full w-full object-cover'
          alt='Hero'
        />
        <div className='maxw absolute inset-0 flex items-end justify-start pb-8'>
          <div className='text-4xl font-normal text-white'>{t('products')}</div>
        </div>
      </div>
      <div className='maxw mx-auto mt-10 text-sm font-light'>
        <CustomBreadCrumb
          path={`${t('products')}/${currentProduct?.category.name}/${currentProduct?.name}`}
        />
      </div>
      <div className='maxw mx-auto mt-10 grid h-96 grid-cols-12 gap-5 px-4'>
        <div className='hidden-scrollbar col-span-1 flex h-96 flex-col overflow-y-auto'>
          {currentProduct?.product_images.map((image, index) => (
            <img
              onMouseEnter={() => setCurrentProductImage(image.image)}
              key={index}
              src={image.image}
              alt={currentProduct.name}
              className='mb-2 h-24 w-full object-cover'
            />
          ))}
        </div>
        <div className='col-span-4 flex h-96 justify-center border-2'>
          <img src={currentProductImage} alt='Product' className='h-full object-cover' />
        </div>
        <div className='col-span-7 flex h-96 flex-col justify-between'>
          <div className='flex flex-col gap-4'>
            <div className='text-base font-light text-palette-textSecondary'>
              {currentProduct?.category.name}
            </div>
            <div className='text-xl font-bold text-palette-textSecondary'>
              {currentProduct?.name}
            </div>
            <div>
              {currentProduct?.properties &&
                Object.keys(currentProduct?.properties).map((key, index) => (
                  <div
                    key={index}
                    className={cn(
                      'flex gap-10 py-1 pl-2',
                      index % 2 === 0 ? 'bg-palette-green text-white' : 'text-palette-textSecondary'
                    )}>
                    <div className='w-24 truncate text-base font-light'>{key}</div>
                    <div className='truncate text-base font-light'>
                      {currentProduct?.properties[key]}
                    </div>
                  </div>
                ))}
            </div>
          </div>

          {currentProduct?.product_logos && (
            <div className='flex gap-4'>
              {currentProduct?.product_logos.map((logo, index) => (
                <img
                  key={index}
                  src={logo.image}
                  alt={currentProduct.name}
                  className='h-10 object-contain'
                />
              ))}
            </div>
          )}
          <div className='mx-auto flex w-11/12 flex-col gap-4'>
            <div className='flex justify-between gap-4 px-2'>
              <a
                rel='noreferrer'
                href={currentProduct?.buy_link}
                target='_blank'
                className='flex gap-2 bg-palette-primary px-10 py-1.5 text-white'>
                <img src='/icons/shopping-trolley.svg' alt='Shopping Trolley' className='h-5' />
                <div className='truncate font-medium'>Satın Al</div>
              </a>
              <div className='flex gap-2 bg-palette-green px-10 py-1.5 text-white'>
                <img src='/icons/shopping-trolley.svg' alt='Shopping Trolley' className='h-5' />
                <div className='truncate font-medium'>Teklif Al / Soru Sor</div>
              </div>
            </div>
            <div className='h-1 w-full bg-palette-backgroundDark' />
          </div>
        </div>
      </div>
      <div className='maxw mt-5 flex justify-between gap-5 px-4'>
        {currentProduct?.product_properties.map((propety, index) => (
          <div key={index} className='flex justify-center'>
            <Card key={index} image={MediaURL(propety.image)} title={propety.text_tr} />
          </div>
        ))}
      </div>

      <div className='maxw mx-auto mt-10 flex flex-col items-start gap-4 px-4'>
        <div className='border-b border-palette-textSecondary px-2 text-lg font-semibold text-palette-textSecondary'>
          Ürün Önerileri
        </div>
        <div className='text-base font-normal text-palette-textSecondary'>
          Bu ürünün açıklamalarında veya diğer özelliklerinde bir hata olduğunu düşünüyorsanız
          aşağıdaki formu doldurarak bize geri dönebilirsiniz.
        </div>
        <div className='flex flex-col gap-2'>
          {productPageSuggestOptions.map((item, index) => (
            <div key={index} className='flex items-center gap-2'>
              <input type='checkbox' />
              <div className='text-base font-normal text-palette-textSecondary'>{item}</div>
            </div>
          ))}
        </div>
        <div className='flex w-full flex-col items-start gap-2'>
          <div className='text-base font-normal text-palette-textSecondary'>Notlar</div>
          <textarea className='w-full border border-palette-border' rows={6} />
          <div className='rounded bg-palette-primary px-3 text-[7px] text-base font-normal text-white'>
            GÖNDER
          </div>
        </div>
      </div>
      <div className='my-10 bg-palette-backgroundLighter'>
        <div className='maxw flex flex-col gap-10 py-10'>
          <div className='text-center text-2xl font-medium text-palette-textSecondary'>
            İLGİNİZİ ÇEKEBİLECEK DİĞER ÜRÜNLER
          </div>
          <div className='hidden-scrollbar flex flex-wrap items-center justify-center gap-10 overflow-x-auto'>
            {products.slice(0, 5).map((item, index) => (
              <div key={index} className='flex flex-col gap-2'>
                <img
                  src={MediaURL(item.product_images[0].image)}
                  alt={item.name}
                  className='aspect-square h-auto w-full object-cover'
                />
                <div className='text-center text-xs font-black text-palette-textSecondary'>
                  {item.name}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
