import { useEffect, useState } from 'react';

import { toast } from 'sonner';
import { EPermissions } from 'src/consts/enums';
import useSection from 'src/hooks/useSection';
import { deleteItemService, postItemService, updateItemService } from 'src/service/services';

import InputGenerator from './input-generator';
import { Button } from './ui/button';
import { DrawerClose } from './ui/drawer';

export default function CrudContent({
  type,
  section,
  initialData,
}: {
  type: EPermissions;
  section: ISection;
  initialData?: any;
}) {
  const { getOptions, updateItem, deleteItem, addItem } = useSection();
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState<any>({});
  const [data, setData] = useState<any>(initialData);

  useEffect(() => {
    getOptions(section.endpoint).then(res => {
      setOptions(res.result);
      setIsLoading(false);
    });
    // eslint-disable-next-line
  }, []);

  if (isLoading) {
    return <div className='h-100 w-full'>Loading...</div>;
  }

  const handleCreate = async () => {
    const res = await postItemService(section.endpoint, data);
    if (res?.status === 1) {
      addItem(res.result);
    } else {
      toast.error('Item could not be created');
    }
  };

  const handleUpdate = async () => {
    const changedFields = Object.keys(data).filter(key => data[key] !== initialData[key]);
    const changedData = changedFields.reduce((acc: any, key) => {
      acc[key] = data[key];
      return acc;
    }, {});
    const res = await updateItemService(`${section.endpoint}${initialData.slug}/`, changedData);
    if (res?.status === 1) {
      updateItem(initialData.slug, data);
    } else {
      toast.error('Item could not be updated');
    }
  };

  if (type === EPermissions.delete) {
    return (
      <>
        <div className='p-4 max-w-xl mx-auto'>Bu öğeyi silmek istediğinizden emin misiniz?</div>
        <DrawerClose className='w-full px-4 flex gap-10 pb-5'>
          <Button
            className='w-full'
            onClick={() => {
              deleteItemService(`${section.endpoint}${initialData.slug}/`);
              deleteItem(initialData.slug);
            }}>
            Sil
          </Button>
          <Button variant='outline' className='w-full'>
            Cancel
          </Button>
        </DrawerClose>
      </>
    );
  }

  return (
    <div className='pb-5'>
      <div className='px-4 text-xl'>
        {type === EPermissions.create ? `Create ${section.name}` : `Update ${section.name}`}
      </div>
      <div className='p-4'>
        <InputGenerator
          fields={options.actions.POST}
          form_order={options.form_order}
          data={data}
          setData={setData}
          translationFields={options.translation_fields}
        />
      </div>

      <DrawerClose className='w-full px-4 flex gap-10'>
        <Button
          className='w-full'
          onClick={type === EPermissions.create ? handleCreate : handleUpdate}>
          Submit
        </Button>
        <Button variant='outline' className='w-full'>
          Cancel
        </Button>
      </DrawerClose>
    </div>
  );
}
