import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from 'src/components/ui/breadcrumb';

interface CustomBreadCrumbProps {
  path?: string;
}

export const CustomBreadCrumb = ({ path }: CustomBreadCrumbProps) => {
  const [paths, setPaths] = useState<string[]>([]);
  const location = useLocation();

  useEffect(() => {
    const currentPath = path || location.pathname;
    setPaths(currentPath.split('/').filter(p => p));
  }, [location, path]);

  return (
    <Breadcrumb className='flex'>
      <BreadcrumbList>
        {paths.map((path, index) => (
          <BreadcrumbItem key={index}>
            {index === paths.length - 1 ? (
              <BreadcrumbPage>{path.charAt(0).toUpperCase() + path.slice(1)}</BreadcrumbPage>
            ) : (
              <>
                <BreadcrumbLink href={`/${path}`}>
                  {path.charAt(0).toUpperCase() + path.slice(1)}
                </BreadcrumbLink>
                <BreadcrumbSeparator />
              </>
            )}
          </BreadcrumbItem>
        ))}
      </BreadcrumbList>
    </Breadcrumb>
  );
};
