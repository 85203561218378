import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from 'next-themes';

import Card from './components/card';
import { isgMaterials, news, solutionsContent } from './components/consts';
import SocialIcons from './components/social-icons';
import Footer from './layout/footer';
import Header from './layout/header';

export default function Home() {
  const { t } = useTranslation();
  const { setTheme } = useTheme();

  useEffect(() => {
    setTheme('light');
  }, [setTheme]);

  return (
    <div>
      {/* Eski Gri Header */}
      {/* <div className='bg-palette-background'>
        <div className='h-14 flex maxw items-center justify-between px-5'>
          <div className='flex items-center'>
            <MailIcon className='text-white' size={20} />
            <div className='text-xs font-normal text-white ml-1'>info@ssakimya.com</div>
            <Clock1 className='text-white ml-4' size={20} />
            <div className='text-xs font-normal text-white ml-1'>
              Haftaiçi: 8:00 - 17:30 - Haftasonu: Kapalı
            </div>
            <PhoneCallIcon className='text-white ml-4' size={20} />
            <div className='text-xs font-normal text-white ml-1'>+90 212 262 05 00</div>
          </div>
          <div className='flex items-center'>
            <User className='text-white ml-4' size={20} />
            <div className='text-sm font-light text-white'>Giriş</div>
            <div className='ml-2'>
              <DropDown value='TR' onChange={() => {}} options={['TR', 'EN']} />
            </div>
          </div>
        </div>
      </div> */}
      <Header />

      <aside>
        {/* CAROUSEL */}
        <div className='relative'>
          <img src='images/hero.png' className='w-full' alt='Hero' />
        </div>
        {/* SOLUTION SECTION */}
        <div className='maxw mt-4 flex flex-col items-start justify-between gap-14 bg-palette-background px-5 py-5 md:flex-row md:items-center'>
          {solutionsContent.map((item, index) => (
            <div key={index} className='flex items-center gap-2'>
              <div className='h-8 w-8 rounded-full bg-white' />
              <div className='flex-1'>
                <div className='font-bold text-white'>{item.title}</div>
                <div className='text-xs font-normal text-white'>{item.description}</div>
              </div>
            </div>
          ))}
        </div>
        {/* IS GUVENLIGI MALZEMELERI  */}
        <div className='maxw mt-10 flex flex-col items-center'>
          <div className='text-xl font-normal text-palette-textSecondary'>
            {t('workSafetyMaterials')}
          </div>
          <div className='w-1/2 text-center font-normal text-palette-textSecondary'>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
            has been the industry's.
          </div>
          <div className='mt-5 grid grid-cols-2 gap-4 md:grid-cols-6'>
            {isgMaterials.map((item, index) => {
              const words = item.title.split(' ');
              const lastWord = words.pop();
              const firstWords = words.join(' ');
              return (
                <div key={index} className='flex max-w-52 flex-col items-center gap-2'>
                  <div className='relative'>
                    <img src={item.image} alt={item.title} className='w-full' />
                    <div className='absolute left-0 top-0 z-20 text-4xl font-bold text-white'>
                      {'0' + (index + 1)}
                    </div>
                    <div
                      className='absolute inset-0 opacity-50'
                      style={{ backgroundColor: item.color }}
                    />
                  </div>
                  <div className='text-center text-sm font-light'>
                    {firstWords} <strong>{lastWord}</strong>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* NEWS SECTION */}
        <div className='maxw mt-10 flex flex-col items-center'>
          <div className='text-xl font-normal text-palette-textSecondary'>{t('newsAndBlog')}</div>
          <div className='w-1/2 text-center font-normal text-palette-textSecondary'>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
            has been the industry's.
          </div>
          <div className='mt-5 grid grid-cols-2 gap-4 md:grid-cols-3'>
            {news.map((item, index) => (
              <div
                key={index}
                className='bg- flex max-w-60 flex-col items-center gap-2 overflow-hidden rounded-2xl bg-palette-backgroundLighter'>
                <Card {...item} />
              </div>
            ))}
          </div>
        </div>
        {/* FORM SECTION */}
        <div className='bg-palette-backgroundLight'>
          <div className='maxw mt-10 flex flex-col items-center justify-center gap-5 py-5 md:flex-row'>
            <div className='flex flex-col items-center'>
              <div className='font-base text-3xl text-palette-textSecondary'>
                {t('joinSsaEcosystem')}
              </div>
              <div className='text-sm font-light text-palette-textSecondary'>
                {t('stayInformedSsaChemistry')}
              </div>
            </div>
            <div className='flex h-10 w-80 overflow-hidden rounded-full border-2 border-palette-border bg-white'>
              <input
                className='h-full w-full border-none pl-5 text-sm font-normal outline-none'
                placeholder={t('mailAdress')}
              />
              <button className='h-full rounded-full bg-palette-primary px-8 text-lg font-normal text-white'>
                {t('join')}
              </button>
            </div>
          </div>
        </div>
        {/* SSA TV */}
        <div className='bg-palette-backgroundLighter'>
          <div className='maxw my-10 flex flex-col items-center gap-20 bg-opacity-50 px-10 py-10 md:flex-row'>
            <img src='images/youtube.svg' alt='Youtube' className='h-auto w-full basis-1/2' />
            <div className='basis-1/2'>
              <div className='text-center text-3xl font-normal text-palette-textSecondary'>
                SSA TV
              </div>
              <div className='mt-5 text-center text-base font-normal text-palette-textSecondary'>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                Ipsum has been the industry's
              </div>
              <div className='mt-5 flex justify-center'>
                <SocialIcons />
              </div>
            </div>
          </div>
        </div>
      </aside>
      <Footer />
    </div>
  );
}
