import { createSlice } from '@reduxjs/toolkit';

interface ILanguageState {
  language: ILanguage;
}

const initialState: ILanguageState = {
  language: { name: 'English', code: 'en' },
};

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage(state, action) {
      state.language = action.payload;
    },
  },
});

export const { setLanguage } = languageSlice.actions;
export default languageSlice.reducer;
