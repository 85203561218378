export enum EPermissions {
  create,
  update,
  delete,
}

export enum EStatus {
  idle,
  succeeded,
  failed,
}
