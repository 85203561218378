import { createSlice } from '@reduxjs/toolkit';
import { EStatus } from 'src/consts/enums';

interface IBlogState {
  currentBlog: IBlog | null;
  blogs: IBlog[];
  status: EStatus;
  error: string | null;
}

const initialState: IBlogState = {
  currentBlog: null,
  blogs: [],
  status: EStatus.idle,
  error: null,
};

const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    setBlogs(state, action) {
      state.blogs = action.payload;
    },
    setCurrentBlog(state, action) {
      state.currentBlog = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { setBlogs, setStatus, setError, setCurrentBlog } = blogSlice.actions;
export default blogSlice.reducer;
