import axios from 'src/service/config';

const requestQueue: { [key: string]: Promise<any> | undefined } = {};

const waitForRequest = async (
  url: string,
  method: 'get' | 'post' | 'put' | 'delete' | 'patch' | 'options',
  data?: any
) => {
  if (requestQueue[method + url]) {
    return requestQueue[method + url];
  }
  const requestPromise = new Promise(async (resolve, reject) => {
    try {
      let response;
      switch (method) {
        case 'get':
          response = await axios.get(url);
          break;
        case 'post':
          response = await axios.post(url, data);
          break;
        case 'put':
          response = await axios.put(url, data);
          break;
        case 'delete':
          response = await axios.delete(url);
          break;
        case 'patch':
          response = await axios.patch(url, data);
          break;
        case 'options':
          response = await axios.options(url, data);
          break;
        default:
          throw new Error('Invalid method');
      }
      resolve(response.data);
    } catch (error) {
      reject(error);
    } finally {
      delete requestQueue[method + url];
    }
  });
  requestQueue[method + url] = requestPromise;
  return requestPromise;
};

export const postRequest = (url: string, data: any) => waitForRequest(url, 'post', data);
export const getRequest = (url: string) => waitForRequest(url, 'get');
export const optionsRequest = (url: string) => waitForRequest(url, 'options');
export const putRequest = (url: string, data: any) => waitForRequest(url, 'put', data);
export const deleteRequest = (url: string) => waitForRequest(url, 'delete');
export const patchRequest = (url: string, data: any) => waitForRequest(url, 'patch', data);
