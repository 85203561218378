import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { User } from 'lucide-react';
import { languages } from 'src/consts/languages';
import useCategory from 'src/hooks/useCategory';
import useLanguage from 'src/hooks/useLanguage';
import MediaURL from 'src/utils/mediaURL';

import { nav } from '../../components/consts';
import DropDown from '../../components/dropdown';
import MobileNav from '../../components/mobile-nav';
import SearchBar from '../../components/searchBar';
import SocialIcons from '../../components/social-icons';

export default function Header() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const { categories, getCategories, order } = useCategory();

  const { toggleLanguage } = useLanguage();

  useEffect(() => {
    if (categories.length > 0) return;
    getCategories();
    // eslint-disable-next-line
  }, []);

  const recursiveRenderItem = (category: ICategory) => {
    return (
      <div
        className='flex cursor-pointer flex-col py-2'
        onClick={e => {
          e.stopPropagation();
          navigate('/products/' + category.slug);
        }}>
        <div className='flex gap-4'>
          {category.icon && (
            <img src={MediaURL(category.icon)} alt={category.name} className='h-10 w-10' />
          )}
          <div className='flex items-center'>
            <div className='text-xs font-normal text-palette-text'>{category.name}</div>
          </div>
        </div>
        {category.children.length > 0 &&
          category.children.map((child, index) => (
            <div key={index} className='ml-14'>
              {recursiveRenderItem(child)}
            </div>
          ))}
      </div>
    );
  };

  return (
    <header className='z-10'>
      <div className='maxw flex items-center justify-between p-4'>
        <Link to='/'>
          <img src='/images/logo.svg' alt='SSA Logo' className='h-16' />
        </Link>
        <div className='hidden items-center gap-2 md:flex'>
          <SearchBar />
          <div className='h-8 w-px bg-palette-backgroundDark' />
          <div className='text-sm font-medium'>{t('shop')}</div>
          <div className='h-8 w-px bg-palette-backgroundDark' />
          <div className='truncate text-sm font-medium'>{t('productCatalog')}</div>
          <div className='h-8 w-px bg-palette-backgroundDark' />
          <SocialIcons />
          <div className='h-8 w-px bg-palette-backgroundDark' />
          <Link to='/login' className='flex gap-1'>
            <User className='text-palette-text' size={20} />
            <div className='text-sm font-light text-palette-text'>{t('login')}</div>
          </Link>
          <DropDown
            value={t('VCurrentLanguage')}
            onChange={e => {
              toggleLanguage(languages[e]);
              navigate(0);
            }}
            options={[
              { value: 'en', label: 'EN' },
              { value: 'tr', label: 'TR' },
            ]}
          />
        </div>
        {/* MOBILE NAVIGATION */}
        <div className='flex md:hidden'>
          <MobileNav />
        </div>
      </div>

      <div className='mb-3 flex justify-center md:hidden'>
        <SearchBar isOpen />
      </div>

      {/* DESKTOP NAVIGATION */}
      <nav className='relative hidden gap-4 bg-palette-background pt-2 md:flex'>
        <div className='maxw flex w-full gap-8'>
          {nav.map((nav, index) => (
            <Link
              to={nav.href || ''}
              key={index}
              className='truncate p-4 text-base font-light text-white'
              onMouseEnter={() => {
                nav.title === 'products' && setIsOpen(true);
              }}
              onMouseLeave={() => {
                nav.title === 'products' && setIsOpen(false);
              }}>
              {t(nav.title)}
            </Link>
          ))}
          {/* DESKTOP MENU CONTENT */}
          <div
            className={`absolute left-0 right-0 top-16 z-20 grid bg-white shadow-md ${isOpen ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]'} transition-all`}>
            <div
              className='maxw flex w-full justify-evenly overflow-hidden'
              onMouseLeave={() => setIsOpen(false)}
              onMouseEnter={() => setIsOpen(true)}>
              {order.map((row, index) => (
                <div key={index}>
                  {row.map((col, index) => (
                    <div key={index}>
                      {categories.find(c => c.slug === col) &&
                        recursiveRenderItem(categories.find(c => c.slug === col) as ICategory)}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}
