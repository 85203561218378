import { createSlice } from '@reduxjs/toolkit';
import { EStatus } from 'src/consts/enums';

interface IUserSlice extends IAccess, IRefresh, IUser {}

interface IUserState {
  user: IUserSlice | null;
  status: EStatus;
  error: string | null;
}

const initialState: IUserState = {
  user: null,
  status: EStatus.idle,
  error: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { setUser, setStatus, setError } = userSlice.actions;

export default userSlice.reducer;
