import { useEffect } from 'react';

import { setGlobalConfig } from 'axios-logger';
import i18next from 'i18next';
import useAuth from 'src/hooks/useAuth';
import Navigation from 'src/navigation';

import useLanguage from './hooks/useLanguage';
import { useAppSelector } from './store';

function App() {
  const categories = useAppSelector(state => state.category.categories);
  const { loadUser, refreshToken } = useAuth();
  const { loadLanguage, language } = useLanguage();

  setGlobalConfig({
    headers: true,
  });

  useEffect(() => {
    const refresh = loadUser();
    refreshToken(refresh);
    loadLanguage();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    i18next.changeLanguage(language.code);
  }, [language]);

  return <Navigation />;
}

export default App;
