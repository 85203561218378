import { createSlice } from '@reduxjs/toolkit';
import { EPermissions, EStatus } from 'src/consts/enums';

export const sections: ISection[] = [
  {
    name: 'Blog',
    path: '/',
    endpoint: '/blog/',
    icon: 'cilPenAlt',
    permissions: [EPermissions.create, EPermissions.update, EPermissions.delete],
  },
  {
    name: 'Category',
    path: 'category',
    endpoint: '/category/',
    icon: 'cilPenAlt',
    permissions: [EPermissions.create, EPermissions.update, EPermissions.delete],
  },
  {
    name: 'Products',
    path: 'products',
    endpoint: '/product/',
    icon: 'cilUser',
    permissions: [EPermissions.update, EPermissions.delete, EPermissions.create],
  },
];

interface ISectionsState {
  sections: ISection[];
  status: EStatus;
  error: string | null;
}

const initialState: ISectionsState = {
  sections: sections,
  status: EStatus.idle,
  error: null,
};

const sectionsSlice = createSlice({
  name: 'sections',
  initialState,
  reducers: {
    setSection(state, action) {
      state.sections = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { setSection, setStatus, setError } = sectionsSlice.actions;

export default sectionsSlice.reducer;
