import { createSlice } from '@reduxjs/toolkit';
import { EStatus } from 'src/consts/enums';

interface ICategoryState {
  categories: ICategory[];
  order: string[][];
  status: EStatus;
  error: string | null;
}

const initialState: ICategoryState = {
  categories: [],
  order: [],
  status: EStatus.idle,
  error: null,
};

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setCategories(state, action) {
      state.categories = action.payload;
    },
    setOrder(state, action) {
      state.order = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { setCategories, setStatus, setError, setOrder } = categorySlice.actions;
export default categorySlice.reducer;
