import { useAppDispatch, useAppSelector } from 'src/store';
import { setLanguage } from 'src/store/slices/languageSlice';

export default function useLanguage() {
  const dispath = useAppDispatch();

  const language = useAppSelector(state => state.language.language);

  const loadLanguage = () => {
    const language = localStorage.getItem('language');
    if (language) {
      dispath(setLanguage(JSON.parse(language)));
    }
  };

  const toggleLanguage = (language: ILanguage) => {
    localStorage.setItem('language', JSON.stringify(language));
    dispath(setLanguage(language));
  };

  return { language, loadLanguage, toggleLanguage };
}
