import { createSlice } from '@reduxjs/toolkit';
import { EStatus } from 'src/consts/enums';

interface IProductState {
  currentProduct: IProduct | null;
  products: IProduct[];
  status: EStatus;
  error: string | null;
}

const initialState: IProductState = {
  currentProduct: null,
  products: [],
  status: EStatus.idle,
  error: null,
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setProducts(state, action) {
      state.products = action.payload;
    },
    setCurrentProduct(state, action) {
      state.currentProduct = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { setProducts, setStatus, setError, setCurrentProduct } = productSlice.actions;
export default productSlice.reducer;
